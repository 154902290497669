import * as React from "react";
import "../fonts/fonts.css";
import "./styles.scss";
import Header from "../components/header";
import Footer from "../components/footer";
import ContactForm from "../components/contact-form";
import DATA from "../data";

const IndexPage = () => {
  return (
    <main id="contact">
      <title>{DATA.title} - Contact</title>
      <Header withNavigation />
      <div className="content">
        <div className="inner">
          <div className="grid">
            <div className="pane">
              <ContactForm />
            </div>
            <div className="pane sayHello">
              Say
              <br />
              Hello!
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default IndexPage;
