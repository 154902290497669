export default {
  title: "Visualizations - Bartosz Palus",
  sections: [
    {
      label: "Portfolio",
      url: "/portfolio/",
    },
    {
      label: "About",
      url: "/about/",
    },
    {
      label: "Contact",
      url: "/contact/",
    },
  ],
  homepage: {
    hello: "Hello!",
    caption:
      "I’m Bartosz Palus and I am 3d artist<br/>specializing in 3D renderings<br/>and architectural visualisations",
  },
  footer: {
    quote:
      "I love the moment when an idea that originates from<br/>a discussion transforms into a 3D image. When the<br/>process is collaborative, the final product is more exciting.",
    contact: "+1 647 525 4547<br/>hello@bartoszpalus.com",
  },
  social: {
    linkedin: "https://ca.linkedin.com/in/bartosz-palus-a50a0387",
    behance: "https://www.behance.net/badpanda",
    instagram: "https://www.instagram.com/bartpalus/",
    artstation: "https://www.artstation.com/bartoszpalus",
  },
};
