import * as React from "react";
import "./styles.scss";
import DATA from "../../data";
import { Link } from "gatsby"

// markup
const Navigation = () => {
  return (
    <ul className="navigation">
      {DATA.sections.map((section) => (
        <li key={`section-${section.label}`}>
          <Link to={section.url} activeClassName="active">{section.label}</Link>
        </li>
      ))}
    </ul>
  );
};

export default Navigation;
