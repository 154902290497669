import * as React from "react";
import "./styles.scss";
import diaspora from "../../images/diaspora2.svg";
import instagram from "../../images/instagram.svg";
import behance from "../../images/behance.svg";
import linkedin from "../../images/linkedin.svg";
import artstation from "../../images/artstation.svg";
import DATA from "../../data";

// markup
const FooterComponent = () => {
  return (
    <footer>
      <div className="inner">
        <div className="diaspora">
          <img src={diaspora} alt="Mark" />
        </div>
        <div
          className="section quote"
          dangerouslySetInnerHTML={{ __html: DATA.footer.quote }}
        ></div>
        <div
          className="section"
          dangerouslySetInnerHTML={{ __html: DATA.footer.contact }}
        ></div>
        <div className="social">
          <a target="_blank" href={DATA.social.behance}>
            <img src={behance} alt="Bartosz Palus on Behance" />
          </a>
          <a target="_blank" href={DATA.social.instagram}>
            <img src={instagram} alt="Bartosz Palus on Instagram" />
          </a>
          <a target="_blank" href={DATA.social.linkedin}>
            <img src={linkedin} alt="Bartosz Palus on Linkedin" />
          </a>
          <a target="_blank" href={DATA.social.artstation}>
            <img src={artstation} alt="Bartosz Palus on ArtStation" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
