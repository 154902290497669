import * as React from "react";
import "./styles.scss";
import logo from "../../images/logo.svg";
import Navigation from "../navigation";
import { Link } from "gatsby"

// markup
const HeaderComponent = ({ withNavigation = false }) => {
  if (withNavigation) {
    return (
      <header className="navigation">
        <div className="inner">
          <Link to="/"><img src={logo} className="logo" alt="Bartosz Palus Logo" /></Link>
          <Navigation />
        </div>
      </header>
    );
  }
  return (
    <header>
      <div className="inner">
        <img src={logo} className="logo" alt="Bartosz Palus Logo" />
      </div>
    </header>
  );
};

export default HeaderComponent;
