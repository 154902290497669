import React from "react";
import { useForm } from "react-hook-form";
import "./styles.scss";

const ContactForm = () => {
  const {
    register,
  } = useForm();

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form action="https://getform.io/f/fb9a3a47-46bc-4386-aa51-c4f78687eeb5" method="POST">
      {/* register your input into the hook by invoking the "register" function */}
      {/* <label class="hello">Username</label>
      <input placeholder {...register("example")} /> */}

      {/* include validation with required or other standard HTML validation rules */}
      {/* <label class="hello">Username2</label>
      <input {...register("exampleRequired", { required: true })} /> */}
      {/* errors will return when field validation fails  */}
      {/* {errors.exampleRequired && <span>This field is required</span>} */}

      <div className="group">
        <input type="text" required {...register("name")} />
        <span className="highlight"></span>
        <span className="bar"></span>
        <label>Your name</label>
      </div>

      <div className="group">
        <input type="text" required {...register("email")} />
        <span className="highlight"></span>
        <span className="bar"></span>
        <label>Your email</label>
      </div>

      <div className="group">
        <textarea type="text" required {...register("message")} />
        <span className="highlight"></span>
        <span className="bar"></span>
        <label>Share your thoughts</label>
      </div>

      <input className="sendForm" type="submit" value="Send" />
    </form>
  );
};

export default ContactForm;
